import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import colors from '../styles/base/_colors.module.scss';

import '../styles/components/InputSelector.scss';

const InputSelector = ({
  header, name, value, onChange, required, options, id,
}) => (
  <label className="item input-selector" htmlFor={id}>
    <h3 className="nested-header">{`${header} ${required ? '*' : ''}`.toUpperCase()}</h3>
    <Select
      name={name}
      value={value}
      className="item-selector"
      onChange={(e) => onChange(e.target.value)}
      selected={value}
      id={id}
      variant="standard"
      disableUnderline
      inputProps={{
        sx: { color: colors.primaryDarkBlue },
        MenuProps: {
          MenuListProps: {
            sx: {
              backgroundColor: colors.primarySurface,
              color: colors.primaryDarkBlue,
            },
          },
        },

      }}
      style={{ fontSize: '0.9rem' }}
    >
      {options.map((option) => (
        <MenuItem
          className="input-item"
          value={option.value}
          key={option.value}
        >
          {option.display}
        </MenuItem>
      ))}
    </Select>
  </label>
);

InputSelector.defaultProps = {
  header: '',
  required: false,
  name: '',
  value: '',
  options: [],
  onChange: null,
  id: '',
};
InputSelector.propTypes = {
  header: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    display: PropTypes.string,
  })),
  onChange: PropTypes.func,
  id: PropTypes.string,
};

export default InputSelector;
